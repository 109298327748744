import React from 'react';

import CardRefresh from './CardRefresh';
import CardBonus from './CardBonus';
import TimeLeft from './TimeLeft';
import {
  U23Badge,
  ShirtNumberBadge,
  FirstNumberBadge,
  ReferralBadge,
  RewardBadge,
  CraftedBadge,
  OnSaleBadge,
} from './Badges';

function TimeAndBadges(props) {
  const {
    data,
    hideDateTime,
    showEndDateTime,
    showRefresh,
    onRefresh,
  } = props;

  return (
    <div className="sa-auction-time-badges-block">
      <CardRefresh data={data} show={showRefresh} onRefresh={onRefresh} />
      <OnSaleBadge data={data} show={hideDateTime} />
      <CardBonus data={data.cardBonus} />
      <TimeLeft data={data.endDate} hide={hideDateTime} showEndDateTime={showEndDateTime} />
      <U23Badge data={data.playerBirthDate} />
      <FirstNumberBadge data={data.cardNumber} />
      <ShirtNumberBadge data={data} />
      <ReferralBadge data={data} />
      <RewardBadge data={data} />
      <CraftedBadge data={data} />
    </div>
  );
}

// eslint-disable-next-line import/prefer-default-export
export { TimeAndBadges };
