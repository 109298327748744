const ViewTypes = {
  AUCTIONS: 'auctions',
  MARKET: 'market/listed',
  TOP_PLAYERS: 'topPlayers',
  TOP_NBA_PLAYERS: 'topNbaPlayers',
  ALL_CARDS: 'allCards',
  MANAGER_CARDS: 'managerCards',
  MANAGER_NBA_LINEUP: 'managerNbaLineup',
};

const FilterTypes = {
  CARDS_SORT_ORDER_BY: 'cardsSortOrder',
  SCORES_TYPE: 'scoresType',
  SCORES_SORT_BY: 'scoresSort',
  NBA_SCORES_SORT_BY: 'nbaScoresSort',
  NBA_LINEUP_SORT_BY: 'nbaLineupSort',
  RARITY: 'rarity',
  POSITION: 'position',
  CLUB: 'club',
  NBA_CLUB: 'nbaClub',
  LEAGUE: 'league',
  U23: 'u23',
  FIRST: '1st',
  SHIRT: 'shirt',
  SPECIAL_DESIGN: 'specialDesign',
  LEGENDS: 'legends',
  REWARD: 'reward',
  IN_SEASON: 'inSeason',
  LOW_5TH_SCORE: 'low5thScore',
  PLAYED_LAST_MATCH: 'playedLastMatch',
  PLAYED_PERCENTAGES: 'playedPercentages',
  RARITY_PRICES: 'rarityPrices',
  PRICES: 'prices',
  SCORES: 'scores',
};

const isAvailable = (view, filter) => {
  switch (view) {
    case ViewTypes.AUCTIONS:
      switch (filter) {
        case FilterTypes.RARITY:
        case FilterTypes.POSITION:
        case FilterTypes.CLUB:
        case FilterTypes.LEAGUE:
        case FilterTypes.U23:
        case FilterTypes.FIRST:
        case FilterTypes.SHIRT:
        case FilterTypes.SPECIAL_DESIGN:
        case FilterTypes.IN_SEASON:
        case FilterTypes.PLAYED_LAST_MATCH:
        case FilterTypes.PLAYED_PERCENTAGES:
        case FilterTypes.PRICES:
        case FilterTypes.SCORES:
          return true;
        default:
          break;
      }
      break;

    case ViewTypes.ALL_CARDS:
      switch (filter) {
        case FilterTypes.RARITY:
        case FilterTypes.POSITION:
        case FilterTypes.CLUB:
        case FilterTypes.LEAGUE:
        case FilterTypes.U23:
        case FilterTypes.FIRST:
        case FilterTypes.SHIRT:
        case FilterTypes.SPECIAL_DESIGN:
        case FilterTypes.REWARD:
        case FilterTypes.IN_SEASON:
        case FilterTypes.PLAYED_LAST_MATCH:
        case FilterTypes.PLAYED_PERCENTAGES:
        case FilterTypes.PRICES:
        case FilterTypes.SCORES:
          return true;
        default:
          break;
      }
      break;

    case ViewTypes.MARKET:
      switch (filter) {
        case FilterTypes.RARITY:
        case FilterTypes.POSITION:
        case FilterTypes.CLUB:
        case FilterTypes.LEAGUE:
        case FilterTypes.U23:
        case FilterTypes.FIRST:
        case FilterTypes.SHIRT:
        case FilterTypes.SPECIAL_DESIGN:
        case FilterTypes.REWARD:
        case FilterTypes.IN_SEASON:
        case FilterTypes.PLAYED_LAST_MATCH:
        case FilterTypes.PLAYED_PERCENTAGES:
        case FilterTypes.PRICES:
        case FilterTypes.SCORES:
          return true;
        default:
          break;
      }
      break;

    case ViewTypes.TOP_PLAYERS:
      switch (filter) {
        case FilterTypes.SCORES_TYPE:
        case FilterTypes.SCORES_SORT_BY:
        case FilterTypes.POSITION:
        case FilterTypes.CLUB:
        case FilterTypes.LEAGUE:
        case FilterTypes.U23:
        case FilterTypes.LEGENDS:
        case FilterTypes.PLAYED_LAST_MATCH:
        case FilterTypes.PLAYED_PERCENTAGES:
        case FilterTypes.RARITY_PRICES:
        case FilterTypes.PRICES:
          return true;
        default:
          break;
      }
      break;

    case ViewTypes.TOP_NBA_PLAYERS:
      switch (filter) {
        case FilterTypes.NBA_SCORES_SORT_BY:
        case FilterTypes.NBA_CLUB:
        case FilterTypes.RARITY_PRICES:
        case FilterTypes.PRICES:
          return true;
        default:
          break;
      }
      break;

    case ViewTypes.MANAGER_CARDS:
      switch (filter) {
        case FilterTypes.CARDS_SORT_ORDER_BY:
        case FilterTypes.RARITY:
        case FilterTypes.POSITION:
        case FilterTypes.CLUB:
        case FilterTypes.LEAGUE:
        case FilterTypes.U23:
        case FilterTypes.FIRST:
        case FilterTypes.SHIRT:
        case FilterTypes.SPECIAL_DESIGN:
        case FilterTypes.REWARD:
        case FilterTypes.IN_SEASON:
        case FilterTypes.PLAYED_LAST_MATCH:
        case FilterTypes.PLAYED_PERCENTAGES:
        case FilterTypes.PRICES:
        case FilterTypes.SCORES:
          return true;
        default:
          break;
      }
      break;

    case ViewTypes.MANAGER_NBA_LINEUP:
      switch (filter) {
        case FilterTypes.NBA_LINEUP_SORT_BY:
        case FilterTypes.RARITY:
          return true;
        default:
          break;
      }
      break;

    default:
      break;
  }

  return false;
};

const generateParameters = (viewName, data) => {
  if (!viewName || !data) {
    return [];
  }

  const result = [];

  if (isAvailable(viewName, FilterTypes.CARDS_SORT_ORDER_BY)) {
    result.push(`cardsSortOrder=${data.cardsSortOrderSelectValue}`);
  }

  if (isAvailable(viewName, FilterTypes.SCORES_TYPE)) {
    result.push(`scoresType=${data.scoresTypeSelectValue}`);
  }

  if (isAvailable(viewName, FilterTypes.SCORES_SORT_BY)) {
    result.push(`scoresSort=${data.scoresSortSelectValue}`);
  }

  if (isAvailable(viewName, FilterTypes.NBA_SCORES_SORT_BY)) {
    result.push(`scoresSort=${data.nbaScoresSortSelectValue}`);
  }

  if (isAvailable(viewName, FilterTypes.NBA_LINEUP_SORT_BY)) {
    result.push(`scoresSort=${data.nbaLineupScoresSortSelectValue}`);
  }

  if (isAvailable(viewName, FilterTypes.RARITY)) {
    result.push(`rarity=${data.raritySelectValue.join(',')}`);
  }

  if (isAvailable(viewName, FilterTypes.POSITION)) {
    result.push(`position=${data.positionSelectValue}`);
  }

  if (isAvailable(viewName, FilterTypes.CLUB)) {
    result.push(`club=${data.clubSelectValue}`);
  }

  if (isAvailable(viewName, FilterTypes.NBA_CLUB)) {
    result.push(`club=${data.clubSelectValue}`);
  }

  if (isAvailable(viewName, FilterTypes.LEAGUE)) {
    result.push(`league=${data.leagueSelectValue}`);
  }

  if (isAvailable(viewName, FilterTypes.U23) && data.u23SwitchValue) {
    result.push(`u23=${data.u23SwitchValue}`);
  }

  if (isAvailable(viewName, FilterTypes.FIRST) && data.firstNumberSwitchValue) {
    result.push(`first=${data.firstNumberSwitchValue}`);
  }

  if (isAvailable(viewName, FilterTypes.SHIRT) && data.shirtNumberSwitchValue) {
    result.push(`shirt=${data.shirtNumberSwitchValue}`);
  }

  if (isAvailable(viewName, FilterTypes.SPECIAL_DESIGN) && data.specialDesignSwitchValue) {
    result.push(`special=${data.specialDesignSwitchValue}`);
  }

  if (isAvailable(viewName, FilterTypes.IN_SEASON) && data.inSeasonSwitchValue) {
    result.push(`inSeason=${data.inSeasonSwitchValue}`);
  }

  if (isAvailable(viewName, FilterTypes.LEGENDS) && data.legendsSwitchValue) {
    result.push(`legends=${data.legendsSwitchValue}`);
  }

  if (isAvailable(viewName, FilterTypes.REWARD) && data.rewardSwitchValue) {
    result.push(`reward=${data.rewardSwitchValue}`);
  }

  if (isAvailable(viewName, FilterTypes.LOW_5TH_SCORE) && data.low5thScoreSwitchValue) {
    result.push(`low5thScore=${data.low5thScoreSwitchValue}`);
  }

  if (isAvailable(viewName, FilterTypes.PLAYED_LAST_MATCH) && data.playedLastMatchSwitchValue) {
    result.push(`playedLastMatch=${data.playedLastMatchSwitchValue}`);
  }

  if (isAvailable(viewName, FilterTypes.PLAYED_PERCENTAGES)) {
    result.push(`playedL15=${data.playedFilter.l15}`);
    result.push(`playedL30=${data.playedFilter.l30}`);
    result.push(`playedAll=${data.playedFilter.all}`);
  }

  if (isAvailable(viewName, FilterTypes.RARITY_PRICES)) {
    result.push(`rarityPrices=${data.rarityPricesSelectValue}`);
  }

  if (isAvailable(viewName, FilterTypes.PRICES)) {
    result.push(`minLastAuction=${data.pricesFilter.lastAuction.min}`);
    result.push(`maxLastAuction=${data.pricesFilter.lastAuction.max}`);
    result.push(`minCheapestMarket=${data.pricesFilter.cheapestMarket.min}`);
    result.push(`maxCheapestMarket=${data.pricesFilter.cheapestMarket.max}`);
  }

  if (isAvailable(viewName, FilterTypes.SCORES)) {
    result.push(`minL15=${data.scoresFilter.l15.min}`);
    result.push(`maxL15=${data.scoresFilter.l15.max}`);
    result.push(`minL30=${data.scoresFilter.l30.min}`);
    result.push(`maxL30=${data.scoresFilter.l30.max}`);
    result.push(`minAll=${data.scoresFilter.all.min}`);
    result.push(`maxAll=${data.scoresFilter.all.max}`);
  }

  return result;
};

export {
  ViewTypes,
  FilterTypes,
  isAvailable,
  generateParameters,
};
